import type { RouteLocationNormalized } from 'vue-router'
import {
  PAGE_ADMIN_ACTION_REPOSITORY,
  PAGE_ADMIN_HOME,
  PAGE_ADMIN_PARAMETERS_USERS,
  PAGE_ADMIN_PROGRAM,
  PAGE_ADMIN_PROGRAM_ACTIONS,
  PAGE_ADMIN_PROGRAM_CAMPAIGN_DETAILS,
  PAGE_ADMIN_PROGRAM_CAMPAIGNS,
  PAGE_ADMIN_PROGRAM_KPI,
  PAGE_ADMIN_PROGRAM_PARAMETERS,
  PAGE_ADMIN_PROGRAM_PARAMETERS_COMMUNICATION,
  PAGE_ADMIN_PROGRAM_PARAMETERS_INFOS,
  PAGE_ADMIN_RESULT,
  PAGE_FORBIDDEN,
  PAGE_NEW_CLIENT,
  PAGE_ON_BOARDING_INTRODUCTION,
} from '@/router/routes'
import useUser from '@/hooks/useUser'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'

const { currentUser, canAccessAdmin } = useUser()

export const isAdminRoute = (route: RouteLocationNormalized) => route.matched.some((m) => m.meta.isAdmin)
export const isAdminProgramRoute = (route: RouteLocationNormalized) =>
  route.matched.some((m) => m.name === PAGE_ADMIN_PROGRAM)

export default class AdminGuard {
  public static allowAccessToUsers(to: RouteLocationNormalized) {
    const { canAccessUsers } = currentUser.value?.userRights?.dashboardRights ?? {}
    if (
      to.name !== PAGE_ADMIN_PARAMETERS_USERS ||
      canAccessUsers ||
      currentUser.value?.userRights?.adminRole === FiftyApiClients.FiftyRoleDto.CustomerAdmin
    ) {
      return null
    }
    return { name: PAGE_ADMIN_HOME }
  }

  public static allowAccessToKpi(to: RouteLocationNormalized) {
    const { canAccessResults } = currentUser.value?.userRights?.dashboardRights ?? {}
    if (to.name !== PAGE_ADMIN_RESULT || canAccessResults) {
      return null
    }

    return { name: PAGE_ADMIN_HOME }
  }

  public static allowAccessToActionRepository(to: RouteLocationNormalized) {
    const { canAccessActionRepository } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (
      to.name !== PAGE_ADMIN_ACTION_REPOSITORY ||
      canAccessActionRepository ||
      currentUser.value?.userRights?.adminRole === FiftyApiClients.FiftyRoleDto.CustomerContentAdmin
    ) {
      return null
    }

    return { name: PAGE_ADMIN_HOME }
  }

  public static allowAccessToProgramPopulation(to: RouteLocationNormalized) {
    const { canAccessSettings } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (!isAdminProgramRoute(to) || to.name !== PAGE_ADMIN_PROGRAM_PARAMETERS_INFOS || canAccessSettings) {
      return null
    }

    const { canAccessActions } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (canAccessActions) {
      return { name: PAGE_ADMIN_PROGRAM_ACTIONS, params: { programId: to.params.programId } }
    }

    return { name: PAGE_ADMIN_HOME }
  }

  public static allowAccessToProgramKpi(to: RouteLocationNormalized) {
    const { canAccessResults } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (
      !isAdminProgramRoute(to) ||
      to.name !== PAGE_ADMIN_PROGRAM_KPI ||
      canAccessResults ||
      currentUser.value?.userRights?.adminRole === FiftyApiClients.FiftyRoleDto.CustomerResultAdmin
    ) {
      return null
    }

    return { name: PAGE_ADMIN_PROGRAM_ACTIONS, params: { programId: to.params.programId } }
  }

  public static allowAccessToProgramActions(to: RouteLocationNormalized) {
    const { canAccessActions } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (!isAdminProgramRoute(to) || to.name !== PAGE_ADMIN_PROGRAM_ACTIONS || canAccessActions) {
      return null
    }

    return { name: PAGE_ADMIN_PROGRAM_PARAMETERS, params: { programId: to.params.programId } }
  }

  public static allowAccessToProgramCampaigns(to: RouteLocationNormalized) {
    const { canAccessCampaigns } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (
      !isAdminProgramRoute(to) ||
      !to.name ||
      ![PAGE_ADMIN_PROGRAM_CAMPAIGNS, PAGE_ADMIN_PROGRAM_CAMPAIGN_DETAILS].includes(to.name.toString()) ||
      canAccessCampaigns
    ) {
      return null
    }

    return { name: PAGE_ADMIN_PROGRAM_ACTIONS, params: { programId: to.params.programId } }
  }

  public static allowAccessToProgramCommunications(to: RouteLocationNormalized) {
    const { canAccessCommunications } = currentUser.value?.userRights?.dashboardRights ?? {}

    if (
      !isAdminProgramRoute(to) ||
      to.name !== PAGE_ADMIN_PROGRAM_PARAMETERS_COMMUNICATION ||
      canAccessCommunications
    ) {
      return null
    }

    return { name: PAGE_ADMIN_PROGRAM_ACTIONS, params: { programId: to.params.programId } }
  }

  // TODO HANDLE PROGRAM PARAMS

  public static allowAccess(to: RouteLocationNormalized) {
    if (!isAdminRoute(to)) {
      return null
    }

    /// /
    // need to this because old beforeEach code in main.ts load user after this beforeEach
    // so we need to refactor the old beforeEach (who has circle dependency on $apiManager)
    // before removing this
    if (!currentUser.value) {
      return null
    }

    const client = currentUser.value?.client

    if (
      (client && !client.isOnboarded && client.isInTrial) ||
      (client && !currentUser.value?.isAccountOfficiallyCreated && client.isInTrial)
    ) {
      if (to.name === PAGE_NEW_CLIENT) {
        return null
      }

      return { name: PAGE_NEW_CLIENT }
    }

    // to keep going, currentUser needs:
    // - a created account
    // - access to admin
    if (!currentUser.value?.isAccountOfficiallyCreated) {
      return {
        name: PAGE_ON_BOARDING_INTRODUCTION,
      }
    }

    if (!canAccessAdmin.value) {
      return { name: PAGE_FORBIDDEN }
    }

    if (
      to.name === PAGE_NEW_CLIENT &&
      currentUser.value?.isAccountOfficiallyCreated &&
      currentUser.value?.client?.isOnboarded
    ) {
      return {
        name: PAGE_ON_BOARDING_INTRODUCTION,
      }
    }

    let redirect

    redirect = this.allowAccessToUsers(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToKpi(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToActionRepository(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToProgramPopulation(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToProgramKpi(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToProgramActions(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToProgramCampaigns(to)
    if (redirect) {
      return redirect
    }

    redirect = this.allowAccessToProgramCommunications(to)
    if (redirect) {
      return redirect
    }

    return null
  }
}
