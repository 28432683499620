import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleDown,
  faArchive,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBold,
  faBook,
  faBoxOpen,
  faCalendar,
  faChartColumn,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faClone,
  faCopy,
  faDownload,
  faEdit,
  faEnvelopeOpenText,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFlag,
  faFlagCheckered,
  faHistory,
  faHourglassHalf,
  faInfo,
  faInfoCircle,
  faItalic,
  faKite,
  faLink,
  faList,
  faLock,
  faMinus,
  faMobileAndroid,
  faPause,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faRedo,
  faRepeat,
  faSearch,
  faShareAlt,
  faSignOut,
  faStar,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnderline,
  faUndo,
  faUserFriends,
  faUsers,
  faCircleQuestion,
  faBars,
  faCalendarCirclePlus,
  faCircleCheck,
  faArrowsRepeat,
  faTrophyStar,
  faUndoAlt,
  faFilter,
  faBullseyeArrow,
  faChartLineUp,
  faPartyHorn,
  faCog,
  faBullhorn,
  faChartSimple,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faAngleDown,
  faArchive,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBold,
  faBook,
  faBoxOpen,
  faCalendar,
  faChartColumn,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faClone,
  faCopy,
  faDownload,
  faEdit,
  faEnvelopeOpenText,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFlag,
  faFlagCheckered,
  faHistory,
  faHourglassHalf,
  faInfo,
  faInfoCircle,
  faItalic,
  faKite,
  faLink,
  faList,
  faLock,
  faMinus,
  faMobileAndroid,
  faPause,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faRedo,
  faRepeat,
  faSearch,
  faShareAlt,
  faSignOut,
  faStar,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnderline,
  faUndo,
  faUserFriends,
  faUsers,
  faCircleQuestion,
  faBars,
  faCalendarCirclePlus,
  faCircleCheck,
  faArrowsRepeat,
  faTrophyStar,
  faUndoAlt,
  faFilter,
  faBullseyeArrow,
  faChartLineUp,
  faPartyHorn,
  faCog,
  faBullhorn,
  faChartSimple,
)
