import type { Logger } from '@/store/services/Logger'
import type { Store } from 'vuex'
import type { Router } from 'vue-router'
import type { IRefreshPayload } from '@/store/modules/Login'
import { DISPATCH_REFRESH_USER } from '@/store/modules/Login'
import type { SegmentHelper } from '@/store/services/SegmentHelper'
import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'
import { DISPATCH_LOAD_SESSIONS_DATA } from '@/store/modules/ChallengeSession'
import type VersionManager from '@/store/services/VersionManager'
import LoginManager from '@/store/services/LoginManager'

export default class TabActivityMonitor {
  private logger: Logger
  private store: Store<any>
  private readonly router: Router
  private readonly segment: SegmentHelper
  private readonly apiClients: FiftyApiClientManager
  private readonly versionManager: VersionManager
  private isActive: boolean
  private canBeRefreshed: boolean

  readonly minTimeBeforeRefreshingInMs: number = 60 * 1000 * 30 // 30 min

  constructor(
    logger: Logger,
    store: Store<any>,
    router: Router,
    segment: SegmentHelper,
    apiClients: FiftyApiClientManager,
    versionManager: VersionManager,
  ) {
    this.logger = logger
    this.store = store
    this.router = router
    this.segment = segment
    this.apiClients = apiClients
    this.versionManager = versionManager
    this.isActive = true
    this.canBeRefreshed = false

    const self = this
    window.onblur = function () {
      if (self.isActive) {
        self.onIdle()
      }
    }
    window.onfocus = function () {
      if (!self.isActive) {
        self.onActive()
      }
    }

    this.refreshTimeOut()
  }

  private onTimeOut(): void {
    this.canBeRefreshed = true
  }

  private refreshTimeOut(): void {
    this.canBeRefreshed = false
    const self = this
    setTimeout(function () {
      self.onTimeOut()
    }, this.minTimeBeforeRefreshingInMs)
  }

  public onActive(): void {
    this.isActive = true

    if (!this.canBeRefreshed) {
      return
    }

    this.refreshTimeOut()

    this.versionManager.checkVersion()

    this.refreshUser()
  }

  private refreshUser(): void {
    if (!LoginManager.isLoggedIn()) return

    const payload = {
      apiClients: this.apiClients,
      segment: this.segment,
    } as IRefreshPayload

    this.store
      .dispatch(DISPATCH_REFRESH_USER, payload)
      .then(() => {
        this.store.dispatch(DISPATCH_LOAD_SESSIONS_DATA, payload).catch((res: { message: string }) => {
          if (res.message) {
            this.logger.logError(res.message)
          } else {
            this.logger.logError('Error while loading sessions data')
          }
        })
      })
      .catch((res: { message: string }) => {
        if (res.message) {
          this.logger.logError(res.message)
        } else {
          this.logger.logError('Error while refreshing user')
        }
      })
      .finally(() => {
        this.removeClass('modal-backdrop')
        this.removeClass('modal-open')
      })
  }

  private removeClass(classToRemove: string): void {
    const elements = document.querySelectorAll(`.${classToRemove}`)
    ;[].forEach.call(elements, function (el: Element) {
      el.classList.remove(classToRemove)
    })
  }

  public onIdle(): void {
    this.isActive = false
  }
}
