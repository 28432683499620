import { createStore, type Store } from 'vuex'
import type { InjectionKey } from 'vue'
import { Login, type ILoginModuleState } from './modules/Login'
import { ChallengeSession, type IChallengeSessionModuleState } from '@/store/modules/ChallengeSession'
import { ChallengesStore, type IChallengesState } from '@/store/modules/ChallengesStore'
import { OnBoardingStore, type IOnBoardingState } from '@/store/modules/OnBoardingStore'
import { HabitsStore, type IHabitsState } from '@/store/modules/HabitsStore'

export interface State {
  Login: ILoginModuleState
  ChallengeSession: IChallengeSessionModuleState
  ChallengesStore: IChallengesState
  OnBoardingStore: IOnBoardingState
  HabitsStore: IHabitsState
}

export const key: InjectionKey<Store<State>> = Symbol('Store key')

const store = createStore<State>({
  strict: true,
  modules: {
    Login,
    ChallengeSession,
    ChallengesStore,
    OnBoardingStore,
    HabitsStore,
  },
})
export default store
